// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.bundle.min.js"
// import "bootstrap/dist/js/bootstrap.min.js"
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import "owl.carousel/dist/owl.carousel.min.js"
import "@fortawesome/fontawesome-free/css/all"
import 'wow.js'
// import 'chart.js/auto/auto.js'
import '../packs/jquery.transfer.js'
import '../packs/jquery.overlayScrollbars.min.js'
// import 'select2/dist/css/select2.min.css'
// import 'select2/dist/js/select2.min.js'

// import 'select2/dist/css/select2.min.css'
// import 'select2/dist/js/select2.min.js'
// import 'chart.js/dist/chart.js'

// require('packs/select2setup')

import 'css/site.scss'
// import 'jquery-ui/dist/jquery-ui.min.js'
window.WOW = require('wowjs').WOW;
// window.Chart = require('chart.js').Chart;
// require('packs/select2setup')
// The stylesheet location we created earlier
// require("../stylesheets/application.scss")
// this for custome css
// global.$ = require("jquery")
// global.$ = require("jquery")
// require("jquery-ui")


Rails.start()
Turbolinks.start()
ActiveStorage.start()